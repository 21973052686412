<script>
import WelcomeItem from './WelcomeItem.vue';

export default {
    components: {
        WelcomeItem
    },
    methods: {
        toPage(path) {
            this.$router.push({
                path: path
            });
        }
    }
};
</script>

<template>
    <WelcomeItem @click="toPage('/')">
        <template #icon>
            <font-awesome-icon class="dash-icon" icon="tachometer-alt" />
        </template>
        <template #heading>My Dashboard</template>
        Manage your vessels and your fleets in one place.
    </WelcomeItem>

    <WelcomeItem @click="toPage('my-profile')">
        <template #icon>
            <font-awesome-icon class="dash-icon" icon="user-cog" />
        </template>
        <template #heading>My Profile</template>
        View your own profile and other tools.
    </WelcomeItem>

    <!-- <WelcomeItem @click="toPage('/')">
        <template #icon>
            <font-awesome-icon class="dash-icon" icon="book" />
        </template>
        <template #heading>View Documentation</template>
        Everything about how to use OffshoreSMS.
    </WelcomeItem> -->

    <WelcomeItem @click="toPage('/support')">
        <template #icon>
            <font-awesome-icon class="dash-icon" icon="tools" />
        </template>
        <template #heading>Help &amp; Support</template>
        We're here to help and our support is free.
    </WelcomeItem>

    <!-- <WelcomeItem @click="toPage('/')">
        <template #icon>
            <font-awesome-icon class="dash-icon" icon="phone" />
        </template>
        <template #heading>Contact Us</template>
        Give us a call any time on <a href="tel:0447-139-382">0447 139 382</a>
    </WelcomeItem> -->
</template>

<style lang="scss" scoped>
.dash-icon {
    font-size: 1.3rem;
}
</style>
